.markQuee-wrapper {
  background: var(--background);
  margin: 12px 12px;
  border-radius: 32px;
  height: 40px;

  .hero-carousel {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .carousel-card {
      display: flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      z-index: 1;
      padding: 0 0.5rem 0 0;
      border-radius: 32px;
      background-color: var(--background);

      &.item-one {
        width: fit-content;
        display: flex;
        align-items: center;
        height: 100%;
        white-space: nowrap;
        padding-left: 20px;

        @media (max-width: 768px) {
          span {
            display: none;
          }

          .mob_hide-icon {
            display: none;
          }
        }

        span {
          padding: 0;
          text-transform: uppercase;
          font-size: 13px;
          color: var(--white-clr);
          margin: 0 4px 0;
        }

        svg {
          width: 14px;
          height: 14px;
        }
      }

      &.item-two {
        width: 100%;
        padding: 8px 0;
        overflow: hidden;
        background-color: var(--background);
        border-radius: 3.125rem;
        font-size: 13px;

        b {
          margin: 0 3px 0;
          color: var(--white-clr);
        }
      }
    }

    .item-three {
      background-color: var(--drop-down-clr);
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem 0.5rem 1rem;
      font-size: 13px;
      font-size: 13px;
      border-radius: 3.125rem;
      margin-right: -25px;
      position: relative;
      white-space: nowrap;
      gap: 5px;

    

      img {
        width: 21px;
        height: 21px;
      }
    }

    .item-four {
      display: flex;
      align-items: center;
      padding: 0.5rem 0.5rem 0.5rem 30px;
      border-radius: 3.125rem;
      font-size: 13px;
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgb(209, 91, 6) 1%,
        rgb(209, 91, 6) 100%
      );
      white-space: nowrap;

      svg {
        width: 8px;
        height: 8px;
        margin-right: 5px;
      }
    }
  }
}

.marquee-wrapper {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  transform: translate3d(0%, 0, 0);
  animation-name: marquee;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 25s;
}

.marquee a {
  display: inline-flex;
  white-space: nowrap;
  margin: 0px 0px 0px 40px;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: var(--white-clr);
    margin-right: 2px;
  }

  p {
    color: var(--white-clr);
    text-transform: uppercase;
    margin-right: 8px;
    margin-bottom: 0;
  }

  img {
    width: 17px;
    height: 17px;
  }
}

@keyframes marquee {
  0% {
    transform: translate3d(0%, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}


.pokooll{
  width:4px;
margin-left:-7px;
margin-top:-10px;
border-radius: 50000000px;
background-color: white;
}