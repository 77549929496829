.toppair-wrapper {
  margin: 42px 0px;
 
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--search-icon-bg-clr);
    border-radius: 22px;
  }

  .dextboard-content {
    gap: 50px;
    display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 @media screen and (max-width: 767px) {
  gap: 0px !important;
 }
  }
}


@media screen and (max-width: 757px) {
  .toppair-wrapper {
    margin: 42px 0px;
    height:fit-content;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--search-icon-bg-clr);
      border-radius: 22px;
    }
  
    .dextboard-content {
      gap: 50px;
      display: flex;

    }
  }

  .toppair-wrappers{
    margin: 50px 0px;
    height:fit-content;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--search-icon-bg-clr);
      border-radius: 22px;
    }
  
    .dextboard-content {
      gap: 50px;
      display: flex;

    }
  }
}

.toprr{
  @media screen and (max-width: 757px) {

  margin-top: -100px;
}}