.product-detail {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  transition: all 0.5s ease-in-out;

  .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0%;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
  }

  .product-detail-wrap {
    position: relative;
    overflow-y: auto;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #142028;
    .close-icon {
      display: flex;
      cursor: pointer;
      padding: 8px;
      justify-content: flex-end;
      margin: 12px;
    }
  }
  .layout-module-children {
    overflow-y: auto;
    // height: 100%;

    &::-webkit-scrollbar {
      width: 5px;
    }
  }
}

.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px);
  z-index: 100;
  overflow-y: auto;
}

.base-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: var(--background);
  border-radius: 10px;
  max-width: 530px;
  width: 100%;
  background-color: var(--background);
  padding: 20px 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 7px 0px rgba(189, 159, 58, 0.6);
  text-align: center;
  // max-width: 360px;
  width: 100%;

  @media (max-width: 576px) {
    max-width: 90%;
  }
}
