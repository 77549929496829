.post-a-comment-wrapper {
  background: var(--background);
  border-radius: 12px;
  margin-top: 12px;

  .comment-head {
    background: var(--drop-down-clr);
    padding: 8px 14px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    h5 {
      font-family: var(--font-light);
      font-size: 12px;
      letter-spacing: 0.5px;
    }
  }
  .comment-content {
    padding: 18px 12px;
    .btns {
      gap: 6px;
      padding: 12px 0;
      button {
        font-family: var(--font-medium);
        font-size: 12px;
        cursor: pointer;
      }
    }
    .choose-input-file {
      label {
        svg {
          width: 18px;
        }
        input {
          display: none;
        }
        .flex-item {
          gap: 6px;
          background: var(--drop-down-clr);
          border-radius: 8px;
          padding: 10px 12px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          border: 1px dashed var(--white-clr);
        }
      }
    }

    .error-input {
      font-size: 12px;
      color: tomato;
    }
    button {
      background-color: #ffba00;
      padding: 8px 16px;
      border: none;
      border-radius: 10px;
    }
    input {
      outline: none;
      border: none;
      background: var(--drop-down-clr);
      border-radius: 8px;
      padding: 10px 12px;
      color: var(--white-clr);
      width: 100%;
      font-size: 13px;
      &::placeholder {
        color: var(--search-clr);
      }
    }
    .comment-btn {
      justify-content: center;
      margin-top: 12px;
      button {
        border: none;
        outline: none;
        background: var(--text-secondary-clr);
        padding: 8px 14px;
        border-radius: 12px;
        width: 50%;
        font-size: 12px;
        color: var(--white);
        cursor: pointer;
        font-family: var(--font-semiBold);
      }
    }
  }
}
