.trade-history {
  .table-wrapper {
    td {
      height: 35px;
      color: #cdffe7;
      padding: 8px;
      box-sizing: border-box;
      
.buy {
  color: var(--green-clr);
}

.sell {
  color: red;
}


      // .type {
      //   color: var(--green-clr);
      //   text-align: center;
      //   display: block;
      // }

      .flex-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;

        > div {
          cursor: pointer;
          svg {
            width: 15px;
            height: 15px;
          }
        }

        img {
          width: 15px;
          height: 15px;
          object-fit: contain;
        }
      }
    }
  }
}

