.pairExplorer-info-wrapper {
  // height: 200px;
  .block-left {
    justify-content: space-between;
    gap: 5px;
    padding: 4px 12px;
    border-radius: 12px;
    background: var(--background);

    .swap-head {
      justify-content: center;
      flex: 1;
      padding: 4px 18px;
      cursor: pointer;
      border-radius: 8px;

      &.active {
        background-color: var(--drop-down-clr);
      }
      svg {
        width: 13px;
        color: var(--search-clr);
        margin-right: 8px;
      }
      span {
        color: var(--search-clr);
        font-size: 14px;
      }
    }
  }
  .info-content {
    background: var(--background);
    border-radius: 12px;
    margin-top: 12px;
  }
  .advertisement-wrapper {
    padding: 4px 12px;
    border-radius: 12px;
    background: var(--drop-down-clr);
    margin-top: 12px;
    cursor: pointer;
    &:hover {
      background: var(--background);
    }
    .advertisement-content {
      justify-content: space-between;

      .flex-item {
        gap: 16px;
        img {
          width: 32px;
          height: 32px;
          border-radius: 24px;
        }
        h5 {
          font-family: var(--font-light);
          font-size: 12px;
        }
      }
      .ad-para {
        gap: 10px;
        p {
          font-size: 13px;
        }
        svg {
          width: 9px;
          color: var(--search-clr);
          cursor: pointer;
        }
      }
    }
    .play-now-content {
      margin: 6px 0 8px;
      p {
        color: var(--white-clr);
        font-size: 13px;
      }
      a {
        margin-left: 4px;
        color: var(--text-secondary-clr);
      }
    }
  }
  .ad-img {
    margin: 12px 0;
    img {
      border-radius: 16px;
    }
  }
}
