.hotpairs-container {
  margin-bottom: 1.25rem;

  .live-data-card-container {
    background: var(--background);
    border-radius: 12px;
    padding: 22px 14px;
    position: relative;
    z-index: 1;
    // width: fit-content;

    .border-animation-wrapper {
      overflow: hidden;
      border-radius: 12px;
      position: absolute;
      inset: 0;
      z-index: -1;
      // display: none;

      .border-animation {
        border-radius: 12px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -5px;
        left: -5px;
        right: -5px;
        bottom: -5px;
        z-index: -1;

        &::before {
          content: "";
          background-image: conic-gradient(var(--text-secondary-clr) 30deg, transparent 120deg);
          width: 150%;
          height: 150%;
          position: absolute;
          animation: rot 2s linear infinite;
          border-radius: 12px;
          // opacity: 0;
          // animation-play-state: paused;

          @keyframes rot {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(-360deg);
            }
          }
        }

        &::after {
          content: "";
          inset: 10px;
          position: absolute;
          background: var(--background);
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: larger;
          letter-spacing: 5px;
        }
      }
    }

    .live-data-card {
      justify-content: space-between;
      gap: 4px;
      .daily-gainers-heading {
        gap: 11px;
        margin-bottom: 16px;
        .chat-icon {
          svg {
            width: 16px;
            color: var(--white-clr);
          }
        }
        p {
          font-size: 20px;
          line-height: 1.2;
          color: #ffba00;
          font-family: var(--font-medium);
        }
        .iicon {
          svg {
            width: 12px;
            color: var(--search-clr);
          }
        }
      }
      .more-content {
        gap: 2px;
        p {
          color: var(--white-clr);
        }
        svg {
          width: 12px;
          color: var(--text-secondary-clr);
        }
      }
    }
    .row-content {
      gap: 42px;
      width: 100%;
      overflow-x: auto;
    }

    .live-data-content {
      column-count: 3;
      column-rule: 2px solid var(--search-icon-bg-clr);
      column-gap: 52px;
      width: 100%;
      // overflow-x: auto;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--search-icon-bg-clr);
        border-radius: 22px;
      }

      @media (max-width: 1280px) {
        width: 1160px;
      }

      .live-data-item-content {
        justify-content: space-between;
        padding: 8px 12px;
        border-radius: 8px;
        cursor: pointer;
        white-space: nowrap;
        min-width: 300px;

        &:hover {
          background: var(--search-icon-bg-clr);
        }
        .data-items {
          gap: 7px;
          p {
            font-size: 13px;
            color: #ffba00;
            margin-right: 4px;
          }
          .profit-loss-icon {
            margin-right: 4px;
            svg {
              width: 11px;
            }
          }

          .flex-item {
            h4 {
              font-size: 14px;
              font-family: var(--font-light);
              margin-left: 12px;
              color: var(--white-clr);
            }
            p {
              color: var(--search-clr);
              font-size: 11px;
              margin-left: 4px;
              text-transform: uppercase;
            }
          }
        }
        .range-content {
          gap: 32px;
          p {
            color: var(--green-clr);
            font-size: 13px;
          }
          .arrow {
            gap: 4px;
            svg {
              color: var(--green-clr);
            }
            p {
              color: var(--green-clr);
              font-size: 13px;
            }
          }
        }
      }
    }
    .bodrer-right {
      border-right: 1px solid var(--search-btn-clr);
      height: 170px;
      margin-top: 20px;
    }
  }
}


table{
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  @media (max-width: 768px) {
    max-width:100%;
    overflow:auto;

  }


  td{
     padding: 10px;
    font-size:14px;
    // width:fit-content;
    }
}



.percentson{
  margin-left: 55px;
  @media (max-width: 768px) {
    margin-left: 0px;
    display: none;


  }
}