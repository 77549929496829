.sidebar-wrapper {
  position: relative;
  width: 350px;
  position: fixed;
  top: 0;
  left: 0;
  width: 64px;
  bottom: 0;
  background: var(--background);
  z-index: 100;
  @media (max-width: 992px) {
    display: none;
  }

  .sidebar-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: var(--background);
    transition: width 200ms linear;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--search-icon-bg-clr);
      border-radius: 22px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;

      > nav {
        flex: 1;
      }
    }

    &:hover {
      width: 220px;

      .flex-item {
        span {
          display: flex;
          color: var(--white-clr);
        }
      }

      .sidebar-footer {
        .powered-by {
          display: block;
        }

        .controls {
          display: block;
        }

        .social-links {
          display: flex;
        }
      }
    }

    .logo {
      height: 60px;
      display: flex;
      align-items: center;
      padding: 12px;

      img {
        width: 42px;
      }
      span {
        font-family: var(--font-bold);
        font-size: 14px;
      }
    }
    .list {
      padding: 12px 20px;
      img {
        margin-bottom: 6px;
      }
      &:hover {
        background: var(--search-icon-bg-clr);
      }
    }

    .flex-item {
      gap: 10px;
      align-items: center;

      svg {
        color: var(--icon-clr);
      }
      span {
        font-size: 14px;
        display: none;
        padding-bottom: 0;
      }
    }
    .sidebar-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 10px;
      border-top: 1px solid rgba(255, 255, 255, 0.2);

      .stats {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > span {
          padding: 4px 8px;
          background-color: var(--drop-down-clr);
          border-radius: 10px;
          font-size: 12px;
          color: var(--white-clr);
        }
      }

      .controls {
        display: none;
      }

      button {
        width: 100%;
        border: none;
        outline: none;
        background: #ffba00;
        padding: 8px 14px;
        border-radius: 12px;
        width: 100%;
        font-size: 12px;
        color: var(--white);
        cursor: pointer;
        font-family: var(--font-semiBold);
      }

      .social-links {
        display: flex;
        align-items: center;
        gap: 10px;
        display: none;

        > a {
          svg {
            width: 16px;
            height: 16px;

            path {
              fill: var(--white-clr);
            }
          }
        }
      }

      .powered-by {
        font-size: 12px;
        color: var(--white-clr);
        display: none;

        > a {
          color: var(--text-secondary-clr);
        }
      }
    }
  }
}
