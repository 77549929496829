.pairExplorer-details-wrapper {
  background: var(--background);
  border-radius: 12px;
  padding: 24px 22px;
  margin-bottom: 1rem;

  .pairExplorer-details-container {
    display: grid;
    justify-content: space-between;
grid-template-columns: 55% 45%;
    .first-step {
      .flex-item {
        gap: 12px;
        img {
          width: 32px;
          height: 32px;
          border-radius: 22px;
        }
        h4 {
          font-size: 24px;
          span {
            font-size: 22px;
            font-family: var(--font-light);
            color: var(--search-clr);
          }
        }

        svg {
          width: 16px;
        }
      }
      .dext-tool-content {
        margin-bottom: 30px;
        h6 {
          font-size: 16px;
          font-family: var(--font-light);
          letter-spacing: 0.8px;
          margin-top: 6px;
        }
        .flex-item {
          gap: 18px;
          p {
            svg {
              width: 12px;
              margin-left: 12px;
            }
          }
        }
        .icons {
          gap: 12px;
          margin: 14px 0;
          svg {
            width: 16px;
          }
        }
        .dot-circle {
          width: 28px;
          height: 28px;
        }
      }
    }
    .second-step {
      .flex-item {
        gap: 12px;
        h1 {
          font-size: 28px;
        }
        .eth {
          h6 {
            font-size: 14px;
            color: var(--green-clr);
            font-family: var(--font-light);
          }
          p {
            font-size: 13px;
          }
        }
      }

      .date-and-hour {
        background: var(--drop-down-clr);
        padding: 8px 18px;
        border-radius: 8px;
        justify-content: space-between;
        margin: 8px 0;
        cursor: pointer;
        p {
          padding: 4px 8px;
          border-radius: 6px;
          &:hover {
            background: var(--over-all-bg-clr);
            color: var(--white);
          }
        }
      }
    }
  }
}

.circle {
  justify-content: center;
  background: var(--drop-down-clr);
  width: 32px;
  height: 32px;
  border-radius: 32px;
}

.pariExplorer-container {
  width: 100%;
  overflow-x: hidden;
}

.pairExplorer-details-tabs-wrapper {
  background: var(--background);
  border-radius: 12px;
  overflow: hidden;

  &-header {
    display: flex;
    padding: 1.2rem 0.6rem;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;

    > p {
    
        color: var(--white-clr);
        margin-top: -10px;
       }
  }
}

.candlestick-chart {
  width: 100%;
}


.poko-chart{
  height:350px;
}

/* Existing CSS code */

@media screen and (max-width: 757px) {
  .pairExplorer-details-wrapper .pairExplorer-details-container {
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }

  .poko-chart{
    height:400px;
  }
}

/* Rest of the existing CSS code */



#dexscreener-embeds{
  position:relative;
  z-index: 2;
  background-color: #142028;
  height:40px;
    margin-top: -22px;

  margin-bottom: -40px;

}




#dexscreener-embedso{
  position:relative;
  z-index: 1;
  background-color: black;
  height:60px;
    // margin-top: -22px;

  margin-bottom: -40px;

}
#dexscreener-embed{
  position:relative;
  z-index: 0;
  // margin-top: -100px;
  width:100%;
  padding-bottom:0px;
}

.poko-first{
  display:flex;
  justify-content: space-between;
  padding-right: 50px;
  margin-top: -40px;
  margin-bottom: 40px;

}
@media(min-width:767px){


  #dexscreener-embed{
    padding-bottom:65%;
  }}

  
  #dexscreener-embed iframe{
    position:absolute;
    width:100%;
    height:400px;
    top:0;
    left:0;
    border:0;}

    @media screen and (max-width: 757px) {
      #dexscreener-embed{
        padding-bottom:95%;
      }

      .dext-tool-content{
        margin-bottom: 100px;
        }

      #dexscreener-embeds{
        position:relative;
        z-index: 2;
        background-color: #142028;
        height:40px;
          margin-top: -22px;
      
        margin-bottom: -40px;
      
      }
      
      #dexscreener-embed iframe{
        // position:absolute;
        width:100%;
        height:250px;
        top:0;
        left:0;
        border:0;}}



        .griding-item{
          display:grid;
          grid-template-columns: 300px auto;
          gap:10px;
        }





        #dexscreener-embedo{
          position:relative;
          z-index: 0;
          // margin-top: -100px;
          width:100%;
          padding-bottom:0px;
        }
@media(min-width:767px){
  #dexscreener-embedo{
    padding-bottom:65%;
  }}
  #dexscreener-embedo iframe{
    position:absolute;
    width:100%;
    height:400px;
    top:0;
    left:0;
    border:0;}

    @media screen and (max-width: 757px) {
      .poko-first{
        display:flex;
        justify-content:start;
        padding-right: 00px;
        margin-top: -20px;
        margin-bottom: 40px;
      
      }
      #dexscreener-embedo{
        padding-bottom:65%;
      }
      #dexscreener-embedo iframe{
        // position:absolute;
        width:100%;
        height:250px;
        top:0;
        left:0;
        border:0;}}



        .griding-item{
          display:grid;
          grid-template-columns: 300px auto;
          gap:10px;
        }



       


        .poko{
          height:420px;
        }


        .option-button{
          background-color: #000000;
          border:none;
          border-radius: 3px;
          color:#fff;
          margin:2px;
          padding:6px 10px;
        }

        .active-opt{
          background-color: #353535;
          /* border: 1px solid #ffba00; */
          border-radius: 3px;
          color:#fff;
          /* font-weight: 700; */
          margin:2px;
          padding:6px 10px;
        }

        