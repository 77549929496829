.pair-explorer-wrapper {
  padding: 12px;

  .grid-item {
    display: grid;
    grid-template-columns: 300px auto 300px;
    gap: 12px;
    @media (max-width: 997px) {
      grid-template-columns: 300px auto;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}
